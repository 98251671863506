/* Surcharges/ Site specific



Styleguide perso
*/


.spip_doc_titre {
    margin-right: auto;
    margin-left: auto;
    font-weight: normal !important;
    /* font-weight: bold; */
        > strong{
            font-weight: normal !important;
        }
}

// ------------------------------------------------*/
// surcharge des plugins
// ------------------------------------------------*/


/* Tabledes matières #tdm

*/
#tdm .encart {
    border: 1px dashed grey;
    display: block;
    float: right;
    margin: 0 0 10px 10px;
    padding: 10px;
    width: 190px;
}

    #tdm h2.menu-titre {
        font-size:1.5em;
        margin:0;
        padding:0;
        color:#7E7E7E;
    }
    
    #tdm ul {
        margin:0;
        padding:0;
        list-style-type:none;
    }
    #tdm .niv1 a{font-weight:bold;}
    #tdm .niv2,.niv2 a {font-weight:normal;text-indent:5px;}
    
    #tdm li a {
        color:#7E7E7E;
        text-decoration:none;
    }
    #tdm li a:hover {
        text-decoration:underline;
    }


/* #MARK : Styles de la barre typo : typoEnluminees */


.texteencadre-spip {
	color: #44413F;
	background: #D8D3D3;
	border-bottom: 2px solid #474848;
	border-left: 1px solid #7E8081;
	border-right: 2px solid #474848;
	border-top: 1px solid #7E8081;
	margin: 1em 0;	
	padding: .25em;	
	position: relative;
}

.caractencadre-spip {
	color:#C4660D;
	font-weight: bold;
}

.caractencadre2-spip {
	color:#810D04;
	font-weight: bold;
}

/* # TYPO */



/* # GxToolBox :  */

#agenda_geoloc #dates_evenement {
  float: left;
  margin-right: 2%;
  width: 50%;
}
#agenda_geoloc #localisation{
    float: left;
    width: 47%;
}

/*
GIS
*/


.carte_gis strong, dfn {
    font-size:1em;
    font-weight: bold;
}
.carte_gis p {
    margin-bottom:0em;
}
.carte_gis a.spip_in {
    color:#394346;
    text-decoration:underline;
    font-size:0.8em;
    text-align:left;
}

/* # pages spécifiques

Description

:hover	- Modifier definition goes here

Markup:
<button class="$modifierClass">Example Button</button>

Styleguide perso.pages-misc
*/
/* #MARK : Hacks spécifiques */

/* #MARK : HACKS : Safari */
@media screen and (-webkit-min-device-pixel-ratio:0){
	#suivez_nous li a {display:inline-block;}
}

/* #MARK : HTML ELEMENTS ET RESET */


a img {
    border:none;
    margin:0;
    padding:0;
}

img{
    border:none;
}

div .colborder {
   border-right:1px dotted #B3B3B3;
   margin-right:13px;
   padding-right:12px;
}


/* #MARK : PAGE : Page Recherche */
#page_recherche {
	background-color: white;
}

/* #MARK : AGENDA_THEMA */

#agenda_thema H1 {
	font-size:1.2em;
	color:#686868;
	text-align:left;
}

/* #MARK : AGENDA -----------------------------  */
#agenda{}

 /* listes de présentations d'événements */
li.item_agenda {
	display:block;
	width:100%;
	border-bottom:1px solid #A9A9A9;
	padding:5px;
	margin-bottom:10px;
	/*padding:5px;*/
    //@include clearfix();
}
.item_agenda img {
	float:left;
	margin:0 10px 0 0;
	padding:0;
	border:1px solid silver;
}

.item_agenda h2 {
	font-size:1em;	
}
.item_agenda p {
	font-size:0.85em;	
}
.item_agenda span {
	float:right;
	font-size:0.75em;
	color:#878787;
}
#agenda ul.thema {
	margin-bottom:5px;
	padding:0;
}


/* #MARK :  MINI CALENDRIER */
/*#calendar{*/
/*	border:1px solid #8B8B8B;*/
/*	margin-bottom:1em;*/
/*}*/
/*#calendar table {*/
/*	font-size:0.8em;*/
/*	margin-bottom:0;*/
/*}*/
/*#calendar table caption a {color:#000000;}*/
/*#calendar span {display:none;}*/
/*#calendar td.occupe {background-color:#B1ACAA;}*/
/*#calendar td.occupe a {color:#FFFFFF;font-weight:bold;}*/


/* #rss_png {bottom: 40px; left: 25px;position:relative;}

#news_png {bottom: 40px; left: 115px;position:relative;} */

a#rss_png span#legende_rss {font-size: 0.6em;bottom: 9px; left: 35px; color : black; width: 60px;}

#legende_news {font-size: 0.6em; position: inherit; bottom: 13px; left: 150px;}

#selectAgenda{
	padding-top:25px;
	padding-right:5px;
	padding-bottom:5px;
	padding-left:20px;
	margin:0;
	text-align:right;
	color:#FFF;
	}
	
	#selectAgenda a {color:#FFF;font-size:0.85em;}
	#selectAgenda a:HOVER {text-decoration:underline;}



div.decoupe_haut{display:none !important;}

.no_image_filtrer
{
	border: 0px !important;
}

div.decoupe_bas
{
	text-align: center;
}


#pratique
{
	
}



#infos
{
	font-size: 0.85em;
	color: white;
	font-weight: bold;
	padding-left: 10px;
	top:0px;
}





/* #MARK :  PAGE : MOTS */
#mots {
/*height: 592px;*/
/*background-color: #DDDDDD;*/
}

	#mots h1 {
	background-color:#FF9900;
	color:white;
	float:left;
	font-size:1.5em;
	font-weight:bold;
	display:block;
	margin-left:5px;
	padding:5px;
	margin-top:5px;
	}



div#agenda1 select , div#agenda1 input[type="text"]
{
	float:right;
	width: 170px;
	font-size: 1em;
	font-family: "Comic sans MS",  sans-serif;
	font-weight: normal !important;
}

#desc
{
	position : inherit;
	right : 0px;
}


	
/* #MARK :  PLAN DU SITE  */

#contenu {
	margin-left:15px;

	}

#contenu h2 {border-bottom:1px solid #A71A1A;color:#A71A1A;}
	#contenu h2 a{color:#A71A1A;}

.contre-encart ul {
	margin:0;
	padding:0;
	list-style-type:none;
	}
	
	.contre-encart li {
	margin:0;
	padding-left:10px;
	list-style-type:none;
	}
	
	.contre-encart li a {color:#7F7F7F;text-decoration:none;}
	.contre-encart li a:hover {text-decoration:underline;}
	


